<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
import BlotFormatter from 'quill-blot-formatter'
import { ImageDrop } from 'quill-image-drop-module'

export default {
  components: {
    QuillEditor
  },
  setup: () => {
    const blotModule = {
      name: 'blotFormatter',
      module: BlotFormatter,
      options: {/* options */}
    }

    const dropModule = {
      name: 'imageDrop',
      module: ImageDrop
    }
    return {blotModule, dropModule}
  },
  data() {
    return {
      id: null,
      board: null,
      openDialog: false,
      options: {
        debug: 'error',
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'image']
        },
        placeholder: '내용을 입력하세요...',
        readOnly: false,
        theme: 'snow'
      },
      toolbarOptions: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        [{ size: ['small', false, 'large', 'huge'] }],
        ['bold', 'italic', 'underline', 'strike'],
        ['blockquote', 'code-block'],
        [{ align: [] }],
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ color: [] }, { background: [] }],
        [{ font: [] }],
        ['link', 'image', 'video'],
        ['clean'],
      ],
      form: {
        key: '',
        title: '',
        content: ''
      }
    }
  },
  mounted() {

  },
  unmounted() {

  },
  methods: {
    open: function(key) {
      this.form.key = key
      this.form.title = ''
      this.form.content = ''
      this.editor.setHTML(this.form.content)

      this.openDialog = true
    },
    save: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.board.create(this.form.key, this.form)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.$emit('searchList')
        this.openDialog = false
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  },
  computed: {
    editor() {
      return this.$refs.quillEditor;
    },
  },
};
</script>

<template>
  <div ref="contentWrapper">
    <b-modal
        title='게시판 글작성'
        v-model='openDialog'
        size='xl'
        scrollable
        centered
        button-size='sm'
        hide-footer
    >
      <div class='d-block'>
        <form @submit.prevent="process">
          <div class='form-group mb-2'>
            <input type='text' class='form-control' :placeholder="$t('제목')" v-model="form.title" />
          </div>
          <div class='form-group'>
            <QuillEditor ref="quillEditor" style="height: 300px;" :toolbar="toolbarOptions" :options="options" :modules="[blotModule, dropModule]" v-model:content="form.content" content-type="html" />
          </div>
          <div class='text-end'>
            <hr />
            <button type='submit' class='btn btn-primary me-1' @click='save'>{{ $t('저장') }}</button>
            <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
