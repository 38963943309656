<script>
import Read from './read'
import Write from './write'

export default {
  components: {
    Read,
    Write
  },
  props: {
    boardKey: String
  },
  data() {
    return {
      search: {
        searchType: 'username',
        searchText: '',
        beginDate: null,
        endDate: null
      },
      sort: { createdAt: -1 },
      list: [],
      limit: 25,
      totalCount: 0, // 총갯수
      totalPageCount: 0, // 총 페이지수
      page: 1 // 현재 페이지
    }
  },
  created() {

  },
  mounted() {
    const a = localStorage.getItem('limit')
    this.limit = (a !== 'NaN' && a) ? parseInt(a) : 50

    this.searchList().then()
  },
  methods: {
    setSort: function(field) {
      let dir
      if (this.sort[field]) {
        dir = this.sort[field] * -1
      } else {
        dir = -1
      }

      this.sort = {}
      this.sort[field] = dir
      this.searchList().then()
    },
    searchByPage: function(page) {
      this.page = page
      this.searchList().then()
    },
    searchList: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        localStorage.setItem('limit', this.limit)

        const params = {
          search: {},
          sdate: this.search.beginDate,
          edate: this.search.endDate,
          sort: this.sort,
          limit: this.limit,
          page: this.page
        }
        params.search[this.search.searchType] = this.search.searchText

        const result = await this.$API.board.list(this.boardKey, params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        let totalCount = result.totalCount - ((result.page-1) * result.limit)
        for(const key in result.documents) {
          const document = result.documents[key]
          document.number = totalCount
          totalCount--
        }

        this.list = result.documents
        this.totalCount = result.totalCount
        this.totalPageCount = result.totalPageCount ? result.totalPageCount : 1
        this.page = result.page
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openRead: async function(id) {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.board.read(this.boardKey, id)
        if (result.code !== 0) {
          return alert(result.msg)
        }
        for(const key in this.list) {
          const document = this.list[key]
          if (document._id === id) {
            this.list[key].readCount = result.document.readCount
            break
          }
        }
        this.$refs['board-document-read'].open(result.document)
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openWrite: function() {
      this.$refs['board-document-write'].open(this.boardKey)
    },
  },

}
</script>

<template>
  <div ref="contentWrapper">
    <form class="header-search" @submit.prevent="searchList()">
      <div class="row mb-3">
        <div class="col-md-1 text-end">
          <button type="button" class="btn btn-primary w-100 h-100" @click="openWrite" v-if="$store.getters['auth/user'].type === 'admin'">글작성</button>
        </div>
        <div class="col-md-6">
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <select class="form-select" v-model="search.searchType">
              <option value="title">제목</option>
              <option value="contentStripped">내용</option>
              <option value="username">아이디</option>
            </select>
          </div>
        </div>
        <div class="col-md-2">
          <div class="form-group">
            <input type="text" class="form-control" :placeholder="$t('검색어')" v-model="search.searchText" />
          </div>
        </div>
        <div class="col-md-1">
          <button type="submit" class="btn btn-primary w-100"><span class="fa fa-search"></span></button>
        </div>
      </div>
    </form>
    <div class="table-response">
      <table class="table table-hover table-striped sticky-table">
        <colgroup>
          <col style="width: 100px;" />
          <col />
          <col style="width: 100px;" />
          <col style="width: 180px;" />
        </colgroup>
        <thead class="table-dark">
        <tr>
          <th>#</th>
          <th>제목</th>
          <th>조회수</th>
          <th>일자</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="document in this.list" :key="document._id">
          <td>{{ document.number.formatThousands() }}</td>
          <td class="text-start"><a href="javascript:void(0)" @click="openRead(document._id)" v-html="document.title"></a></td>
          <td class="text-end">{{ document.readCount.formatThousands() }}</td>
          <td class="small">{{ new Date(document.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <ul class="pagination pagination-rounded justify-content-center mb-2">
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="First">
          <i class="mdi mdi-chevron-double-left"></i>
        </a>
      </li>
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="Previous">
          <i class="mdi mdi-chevron-left"></i>
        </a>
      </li>
      <li class="page-item" :class="{ 'active': pageIndex === page }" v-for="pageIndex in totalPageCount" :key="'page-' + pageIndex">
        <a class="page-link" href="javascript: void(0)" @click="searchByPage(pageIndex)">{{ pageIndex }}</a>
      </li>
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="Previous">
          <i class="mdi mdi-chevron-right"></i>
        </a>
      </li>
      <li class="page-item disabled">
        <a class="page-link" href="javascript: void(0)" aria-label="First">
          <i class="mdi mdi-chevron-double-right"></i>
        </a>
      </li>
    </ul>

    <Read ref="board-document-read" @searchList="searchList" />
    <Write ref="board-document-write" @searchList="searchList" />
  </div>
</template>
